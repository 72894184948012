<template>
	<nav class="nav">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10414')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<div id="container" class="container" v-if="room !== undefined">
		<div id="sub" class="sub booking">
			<div class="row">
				<!-- 예약 숙소 -->
				<section class="booking-top">
					<h2 class="booking-top__title" v-if="lang === 'ko'">{{room.hotel_nm}}</h2>
					<h2 class="booking-top__title" v-else>{{room.hotel_enm}}</h2>
					<p class="booking-top__desc">
						<span class="booking-top__badge">{{isDayuse ? t('10558') : t('10555')}}</span>
						{{room.prod_nm}}
					</p>
				</section>
				<!-- 체크인 / 아웃 -->
				<section class="checktime">
					<div class="checktime__item">
						<div class="checktime__title">{{t('10270')}}</div>
						<h3 class="checktime__date">
							{{sdate}}
						</h3>
						<div class="checktime__time">
							{{isDayuse ? sdayuse : room.stay_stime}}
						</div>
					</div>
					<div class="checktime__dot">
						<i class="icon icon-rightbig--blur"></i>
					</div>
					<div class="checktime__item">
						<div class="checktime__title">{{t('10271')}}</div>
						<h3 class="checktime__date">
							{{isDayuse ? sdate : edate}}
						</h3>
						<div class="checktime__time">
							{{isDayuse ? edayuse : room.stay_etime}}
						</div>
					</div>
				</section>
				<section class="content">
					<!-- 대실 이용시간 변경하기 -->
					<article class="booking-content" v-if="isDayuse">
						<button type="button" class="btn btn-change" @click="handleOpenSelectDayuse">
							{{t('10510')}}
						</button>
					</article>
					<!-- 취소 및 환뷸 규정 -->
					<article class="booking-content">
						<div class="booking-subtitle">
							<h3>{{t('10511')}}</h3>
							<router-link to="/layers/terms/cancel2">{{t('10512')}}</router-link>
						</div>
						<p class="description" v-if="hotelInfo.cancel_rule !== null" v-html="hotelInfo.cancel_rule.split('\n').join('<br />')">
						</p>
					</article>
					<!-- 최종 금액 -->
					<article class="booking-content">
						<ul class="info">
							<li class="point point2" style="padding-right:0">
								<h4 class="info__title">
									{{isDayuse ?  t('10558')+ `(${room.dayuse_hours}`+t('10059')+")" : `${days}`+t('10855')}}
								</h4>
								<div class="info__wrap">
									<div class="info__text">
										<strong class="red">{{(isDayuse ? room.dayuse_amt : room.sales_amt).toLocaleString("en-US")}}</strong> {{t('10088')}}
									</div>
								</div>
							</li>
						</ul>
					</article>
					<!-- 버튼 -->
					<div id="navigation" class="navigation navigation--noborder">
						<Toast v-if="openToast" :type="'cart'" :handleClose="handleCloseToast" :closeSecond="3" />
						<div class="btns btns--centered">
							<button type="button" class="btn btn-primary purple" @click="handleOpenToast">
								{{t('10513')}}
							</button>
							<button type="button" class="btn btn-confirm" @click="handleOrder">
								{{t('10514')}}
							</button>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
	<SelectDayuse v-if="openSelectDayuse" :handleClose="handleCloseSelectDayuse" :room="room" :handleSelect="selectDayuse" :sdayuse="sdayuse" :sdate="sdateValue" />
	<MessageLogin v-if="openLogin" :handleClose="handleCloseLogin" :handleSubmit="handleSubmit" />
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex'
import Time from "@/components/layers/Time";
import MessageLogin from '@/components/layers/Message_login';
import Toast from "@/components/Toast";
import dayjs from "dayjs";
import api from "@/urls/mmy01";
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
dayjs.locale("ko");

export default {
	components: {
		SelectDayuse: Time,
		Toast,
		MessageLogin,
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const lang = ref();
		const router = useRouter();
		const dayuse = ref();
		const { t }= useI18n() //번역필수 모듈
		const openSelectDayuse = ref(false);
		const openToast = ref(false);
		const openLogin = ref(false);
		
		const userData = computed(() => store.state.userData);
    	const isLogin = computed(() => Object.keys(userData.value).length !== 0);

		const hotelNo = route.query.hotel_no;
		const prodNo = route.query.prod_no;
		const isDayuse = route.query.dayuse === "true";

		const hotelInfo = computed(() => {
			const hotelInfos = store.state.mrm02.mrm0201[hotelNo];
			if(hotelInfos && hotelInfos.length > 0){
				return hotelInfos[0];
			}
			return undefined;
		});

		const room = computed(() => store.state.mrm02.room[hotelNo]?.[prodNo]);
		const sdate = computed(() => {
			const dateRange = store.state.mhm01.selectedDateRange;
			if(dateRange) {
				return dayjs(dateRange.start).format("YYYYMMDD");
			}
			return dayjs().format("YYYYMMDD");
		});

		const edate = computed(() => {
			const dateRange = store.state.mhm01.selectedDateRange;
			if(dateRange) {
				return dayjs(dateRange.end).format("YYYYMMDD")
			}
			return dayjs().add(1, "day").format("YYYYMMDD");
		});

		const sdayuse = computed(() => {
			if(dayuse.value) {
				return dayuse.value;
			}
			const dateRange = store.state.mhm01.selectedDateRange;
			const nowHour = dayjs().hour();
			const startHour = room.value ? Number(room.value.dayuse_stime.split(":")[0]) : nowHour;
			if (dateRange && dayjs(dateRange.start).diff(dayjs().startOf('day'), "day") === 0) {
				return String(startHour > nowHour ? startHour : nowHour + 1).padStart(2, '0') + ":" + room.value.dayuse_stime.split(":")[1];	
			} else {
				return String(startHour).padStart(2, '0') + ":" + room.value.dayuse_stime.split(":")[1];
			}
		});

		const edayuse = computed(() => {
			if(room.value === undefined) {
				return sdayuse;
			}
			return String(Number(sdayuse.value.split(":")[0]) + room.value.dayuse_hours).padStart(2, '0') + ":" + sdayuse.value.split(":")[1]; 
		})

		const days = computed(() => {
			const dateRange = store.state.mhm01.selectedDateRange;
			if(dateRange) {
				return dayjs(dateRange.end).diff(dateRange.start, "day");
			}
			return 1;
		})

		onMounted(() => {
			if(room.value === undefined){
				store.dispatch("mrm02/fetchRoomDetailRoom", { 
					hotelNo, 
					prodNo, 
					arrDate: sdate.value,
					depDate: isDayuse ? sdate.value : edate.value,
				});
			}
			if(hotelInfo.value === undefined) {
				store.dispatch("mrm02/fetchMrm0201", { hotelNo });
			}
			store.dispatch("mcs01/fetchMcs01", { proc_cd: "09" });
			
			lang.value = store.state.app.lang;
			i18n.global.locale.value = store.state.app.lang;
		})

		const handleOrder = () => {
			store.commit("order/SET_ORDER_ITEMS", { orderItems: [{
				hotelNo,
				prodNo,
				isDayuse,
				sdate: sdate.value,
				edate: edate.value,
				sdayuse: sdayuse.value,
				edayuse: edayuse.value,
				adult_cnt: store.state.mhm01.selectedMember?.adultCount ?? 2,
				child_cnt: store.state.mhm01.selectedMember?.childCount ?? 0,
			}]});
			router.push("/order/apply");
		}

		const handleOpenToast = async () => {
			if(openToast.value) {
				return;
			}
			if(isLogin.value){
				await api.getMmy01091({
					proc_cd: "01",
					mem_id: userData.value.mem_token,
					hotel_no: hotelNo,
					prod_no: prodNo,
					prod_type: isDayuse ? "D" : "S",
					prod_amt: isDayuse ? room.value.dayuse_amt : room.value.sales_amt,
					prod_cnt: 1,
					sdate: sdate.value,
					edate: isDayuse ? sdate.value : edate.value,
					stime: isDayuse ? sdayuse.value : room.value.stay_stime,
					etime: isDayuse ? edayuse.value : room.value.stay_etime,
					adult_cnt: store.state.mhm01.selectedMember?.adultCount ?? 2,
					child_cnt: store.state.mhm01.selectedMember?.childCount ?? 0,
				})
				openToast.value = true;
			} else {
				openLogin.value = true;
			}
		}

		return {
			room,
			sdate: computed(() => dayjs(sdate.value).format("YYYY.MM.DD")),
			sdateValue: sdate,
			edate: computed(() => dayjs(edate.value).format("YYYY.MM.DD")),
			sdayuse,
			edayuse,
			lang,
			t,  //번역필수 모듈
		  i18n,
			goBack() {
				router.back();
			},
			selectDayuse(time) {
				dayuse.value = time;
				openSelectDayuse.value = false;
			},
			isDayuse,
			openSelectDayuse,
			handleOpenSelectDayuse() {
				openSelectDayuse.value = true
			},
			handleCloseSelectDayuse() {
				openSelectDayuse.value = false
			},
			days,
			handleOpenToast,
			handleCloseToast() {
				openToast.value = false;
			},
			handleCloseLogin() {
				openLogin.value = false;
			},
			openToast,
			openLogin,
			handleOrder,
			handleSubmit: async () => {
				router.push("/layers/login");
			},
			hotelInfo
		}
	},
}
</script>